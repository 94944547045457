<script lang="ts" setup>
import { getAccountPermalink } from '~/lib'

const { open } = useLoginModal()
const { isLoggedIn } = useUser()

const onClick = () => {
  if (!isLoggedIn.value) return open()

  navigateTo(getAccountPermalink())
}
</script>
<template>
  <HeaderActionButton
    :class="['desktop-only', 'hover', { 'is-logged-in': isLoggedIn }]"
    :aria-label="isLoggedIn ? $t('account.label') : $t('login')"
    @click="onClick"
  >
    <Icon name="profile" />
  </HeaderActionButton>
  <ShopLoginModal />
</template>
<style lang="scss" scoped>
.button {
  &.is-logged-in {
    color: var(--c-red-100);
  }
}
</style>
